@import url('https://fonts.googleapis.com/css?family=Quicksand');

body {
  /* font-family: 'Courier New, monospace'; */
  color:#2f3131;
  font-weight: 300;
}

.header {
  flex-direction: row;
  background-color: #406c4c;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 65px;
  padding: 0 1rem;
  align-items: center;
};

.headerLeft {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
};


.menuOptions {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.headerLogoText {
  color:#2f3131;
  font-size: 24px;
  text-decoration: none;
}

.active a {
  /* text-decoration: underline; */
  border-bottom: 1px solid #4b5351;
}

.menuItem {
  height: 100%;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.menuItem a {
  text-decoration: none;
  color: #4b5351;
  font-size: 24px;
  padding: 0.3rem 0;
}

.container {
  flex: 1;
  background-color: #eff0ee;
  overflow: hidden;
  width: 100%;
  padding-top: 65px;
}

.homepageContainer {
  background-image: url("../public/wildwood-media-drone31.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 0;
  width: 100%;
  padding-top: 48%;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* 
.homeheight {
  height: 0vh;
} */

.weatherContainer {
  position: absolute;
  left: 10px;
  top: 75px;
}

.rw-container .rw-forecast-days-panel {
  display: none;
  border: none
}

.rw-container .rw-container-main .rw-container-right {
  display: none;
}

.rw-container .rw-container-main .rw-container-left .rw-today .rw-today-hr {
  display: none;
}

.rw-container .rw-container-main .rw-container-left .rw-today .rw-today-info {
  display: none;
}

.centerJustify {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bookingWidgetContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.text {
  font-size: 50px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.homeText {
  font-size: 30px;
  text-align: center;
}

.founderImage {
  width: 25%;
  height: 25%;
  box-shadow: 1px 1px 1px gray;
}

.bookingText {
  width: 60%;
}

.emphesisText {
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.textPadding {
  padding: 2rem;
  width: 50%;
}

.aboutUsButton {
  padding: 20px 25px;
  /* background-color: rgb(37, 70, 37); */
  background-color: #4b5351;
  border-radius: 50px;
  display: flex;
  justify-content: center;
}

.aboutUsButton:hover {
  border: 1px solid (0, 0, 0, 0.8);
  background-color: #f5f5f5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.aboutUsButton:hover a {
  color: black;
  cursor: pointer;
}

.aboutUsText {
  text-decoration: none;
  color: white;
  font-size: 22px;
}

.cabinContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 80%;
  margin: 20px;
  background-color: #eff0ee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.bookingContainer {
  height: 100%;
}

.accomodationsBackground{
  /* background-color: #6a7773; */
}
.bookingCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.cabinHeader {
  border-bottom: 1px solid black;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cabinText {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
}

.cabinBody {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  margin-top: 10px;
}

.cabinImage {
  /* height: 60%; */
  width: 50%;
}

.cabinFeatures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  margin-left: 20px;
}

.logoImage {
  height: 50px;
  width: 50px;
}
.logoImageSecondStyle {
  height: 80px;
  width: 80px;
  margin-bottom: -30px;
}

.homeAboutSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding-bottom: 5rem;
}

.homeAboutSection {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.85);
}

.homeAboutSectionHeader {
  color: #4b5351;
}

.flowerImage {
  height: 150px;
  width: 150px;
  margin-top: 30px;
}

.homeAboutSection p {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: #4b5351;
}

.centerPhotos {
  padding-bottom: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.fullscreen .react-images__positioner div .react-images__dialog .react-images__container .react-images__pager .react-images__frame .react-images__track .react-images__view-wrapper .react-images__view img {
  max-width: 40%;
}

.photoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0rem;
}

.homeButtonText {
  font-size: 20px;
  font-weight: 300;
  color: #f5f5f5;
  text-decoration: none;
  padding: 20px 25px;
  margin: 0;
}

.homeButton {
  border-radius: 5px;
  padding: 20px 0;
  width: 30%;
  text-align: center;
  background-color: #4b5351;
  border-radius: 50px;
}

.homeButtonContainer {
  display: flex;
  justify-content: center;
}

.homeButton:hover {
  background-color: #f5f5f5;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

.homeButtonText:hover {
  color: black;
  cursor: pointer;
}

.homeAboutSectionCon {
  background-size: cover;
  background-color: #eff0ee;
}

.homeTextContainer {
   background-color:rgba(255, 255, 255);
   padding: 0rem 3rem;
   max-width: 40rem;
   border-radius: 3px;
   display: flex;
   justify-content: center;
   flex-direction: column;
   text-align: center;
}

.accomodationsCabinImage {
  width: 100%;
  z-index: -1;
}

.accomodationsCabinImageContainer {
  width: 600px;
  height: 740px;
  overflow: hidden;
}

.accomodationsCabinImageContainerHorizontal {
  height: 500px;
  width: 1000px;
  overflow: hidden;
}


.accomodationsContainerReversed {
  display:flex;
}

.accomodationsCabinImageReversed {
  /* margin-left: -5rem; */
}

.accomodationsSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px 0;
}

.accomodationTextContainer {
  width: 40%;
  padding: 2rem 0rem;
}

.rw-container-right {
  display: none;
}

.view-accomodations-container {
  margin-top: 5rem;
  background-color: #1f2e2e;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 1px 1px 1px gray;
}

.bookNowButton {
  margin-right: 4rem;
  background-color: #1f2e2e;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 1px 1px 1px gray;
}

.bookNowText {
  font-size: 20px;
}

.whiteText {
  color: white;
  text-decoration: none;
}

.accomodationTextContainerReversed {
  margin-left: 0rem;
}

.horizontalLine {
  width: 40%;
  border-top: 1px solid rgb(37, 70, 37);
}

.horizontalLineAboutTile {
  width: 40%;
  border-top: 1px solid #f5f5f5;
}

.homeTextAboutTile {
  color: #f5f5f5;
}

.aboutSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 10rem;  
  width: 100%;
}

.aboutTextContainer {
  height: 60%;
  width: 30%;
  margin-right: -30rem;
  margin-top: 15rem;
  padding: 1rem;
  background-color: #4b5351;
}

.accomodationsCabinImage {
  margin-top: -8rem;
  transition: transition 5s;
}

.accomodationsCabinImage:hover {
  transform: scale(1.075);
}

.photosTileContainer {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.photosTile {
  height: fit-content;
  padding: 10rem 0;
}

.photosTileContainer a {
  width: 33%;
}

.photosTileContainer a:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);

}

.photosTileImage {
  width:100%;
}

.tempBookingText {
  font-size: 30px;
  width: 60%;
  padding-top:5rem;
  text-align: center;
}

.meetTheFoundersText {
  width: 25%;
  font-size: 24px;
  text-align: left;
  /* padding: 10rem 3rem */
}

.founderCon {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.carousel-root {
  width: 80%
}

.control-arrow {
  width: 10%
}

.amenitiesContainer {
  width: 100%;
  background-color: #6a7773;
  padding: 5rem 0rem;
}

.amenityIcon {
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  border: 5px solid #2f3131;
}

.amenityIconText {
  color:white;
  font-size: 20px;
}

.amenityPadding {
  padding-top: 3rem;
}

.footer-container {
  padding: 2rem;
  background-color: #1f2e2e;
}
 

.footer-text {
  margin: 0;
  color: white;
}

.social-media-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.react-tabs {
  width: 100%;
}

.react-tabs__tab-list {
  margin: 0px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.vipList {
  width: 100%;
  text-align: left;
}

.flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.react-tabs__tab-list li {
  border: none;
  bottom: 0;
  padding: 0;
  width: 33%;
}

.react-tabs__tab--selected {
  color:#6a7773;
  bottom: 0;
}

.react-tabs__tab-list li:hover {
  background-color:rgba(0, 0, 0, 0.3)
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.spaceEvenly {
  justify-content: space-evenly;
}

.space-around {
  justify-content: space-around;
}

.center {
  justify-content: center;
  align-items: center;
}

.textCenter {
  text-align: center;
  padding-bottom: 3rem;
}

.bold {
  font-weight: bold;
}

.carousel-root .control-arrow {
  height: '50%';
  align-self: center;
}

.carousel .control-dots {
  margin-top: 25px;
}

.carouselVideoPlayer {
  justify-content: center;
  align-items: center;
  width: "60%";
}

.carouselVideoPlayer .carousel-slider .slider-wrapper .slider .selected {
  height: 75vh;
}

.carouselVideoPlayerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkList {
  width: 60%;
}

.linkListText {
  font-size: 22px;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.shop {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 40px;
  bottom: 40px;
  width: 55px;
  height: 55px;
  background-color: #406c4c;
  border-radius: 100px;
  box-shadow: 1px 1px 1px gray;
  cursor: pointer;
}

.shop:hover {

}

.reviewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  /* /* border: 1px solid #4b5351; */
  /* box-shadow: 1px 1px 1px gray; */
  border-radius: 10px;
  padding: 25px;
  margin-right: 10px;
  flex-shrink: 0;
  max-width: 400px;
  text-align: center;
}

.reviewContainer:hover {
  box-shadow: inset 0 0 3px gray;
}

.moving-list-container {
  width: 100%;
  padding-top: 60px;
  overflow: hidden; /* Hide overflowing items */
}

.moving-list {
  display: flex;
  animation: moveList 30s linear infinite; /* Adjust duration as needed */
}

.moving-list-item {
  flex-shrink: 0; /* Prevent items from shrinking */
  /* width: 100px; Adjust item width as needed */
  margin-right: 20px; /* Adjust spacing between items */
}

@keyframes moveList {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-88%); /* Move the list by its total width */
  }
}

@media (max-width: 1000px) {

  .linkList {
    width: 100%;
  }

  .weatherContainer {
    position: inherit;
    padding: 2.5%;
    background-color: #eff0ee;
    width: 95%;
    height: 95%;
  }

  .carouselVideoPlayer {
    justify-content: center;
    align-items: center;
    width: "50vw";
  }

  .reviewContainer {
    max-width: 250px;
  }
  

  .moving-list {
    display: flex;
    animation: moveList 30s linear infinite; /* Adjust duration as needed */
  }

  @keyframes moveList {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-538%); /* Move the list by its total width */
    }
  }
  
  .carouselVideoPlayer .carousel-slider .slider-wrapper .slider .selected {
    height: 100vh;
  }

  .homeTextContainer {
    width: 100%;
    margin: 0;
    align-items: center;
  }

  .homeText {
    padding: 1rem;
    margin: 0;
    font-size: 20px;
  }

  .tab {
    padding: 1rem 0.5rem;
  }

  .aboutUsButton {
    padding: 1rem;
    width: 50%;
  }

  .carouselVideoPlayer {
    justify-content: center;
    align-items: center;
    width: "100vh"
  }

  .tempBookingText {
    font-size: 25px;
  }

  .accomodationsSection {
    /* padding-top: 15rem; */
  }

  .accomodationsCabinImage {
    width: 100%;
  } 

  .accomodationsCabinImageContainer {
    width: 100%;
    height: 100%;
  }

  .accomodationsCabinImage:hover {
    transform: none;
  }

  .accomodationsCabinImageContainerHorizontal {
    width: 100%;
    height: 100%;
  }

  .founderCon {
    flex-direction: column;
    padding: 0;
  }

  .meetTheFoundersText {
    width: 80%;
    padding: 0;
  }
  .founderImage {
    width: 75%;
    height: 75%;
    margin: 2rem;
  }

  .accomodationsSection {
    flex-direction: column;
  }

  .textPadding {
    width: 80%;
  }

  .accomodationTextContainer {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .accomodationsCabinImage {
    width: 100%;
    margin: 0;
  }

  .accomodationTextContainerReversed {
    margin-left:0;
  }

  .accomodationsCabinImageReversed {
    margin: 0;
  }

  .accomodationContainerReversed {
    flex-direction: column-reverse;
    padding-bottom: 80px;
  }

  .tempBookingText {
    padding: 10px;
  }

  .centerPhotos {
    width: 100%;
  }

  .carousel-root {
    width: 100%;
  }

  .fullscreen .react-images__positioner div .react-images__dialog .react-images__container .react-images__pager .react-images__frame .react-images__track .react-images__view-wrapper .react-images__view img {
    max-width: 100%;
  }
}